import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import styled from "@emotion/styled";
import Button from 'react-bootstrap/Button';
// import Radium, {StyleRoot} from 'radium';
import NudgeContent from "../sections/nudge-content-section";

const ButtonClose = styled(Button)`
  color: black;
  background-color: ${props => props.theme.colors.primary_violet};
  font: 28px/28px Roboto;
  font-weight: 500;
  &:hover {
      margin-top:-3px;
  }
`
const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: '5', 
  },
  content: {
    top: '50%',
    left: '80%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-80%, -50%)',
    width: '355px',
    height: '440px',
    backgroundColor: '#8D589B',
    textAlign:'right',
    padding:'0px',
    border: '4px solid rgb(204, 204, 204)',
    borderRadius:'8px',
    boxShadow: '0px 3px 20px',
  },
  '@media (max-width: 767px)': {
    content:{
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
    }
  },
};

function Nudge() {

  const [modalIsOpen, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    let pop_status = sessionStorage.getItem('pop_status');
    console.log(pop_status);
      if(!pop_status){
        setTimeout(() => setShowModal(true), 1000);
        sessionStorage.setItem('pop_status',1);
      }
  }, []); 

  return (
    <div><Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <ButtonClose onClick={closeModal}>x</ButtonClose>
      <NudgeContent></NudgeContent>
    </Modal>
  </div>
  );
}
export default Nudge;

// class Nudge extends React.Component{
//   constructor() {
//     super();
//     this.state = {
//       show: false,
//       pop_status: sessionStorage.getItem('pop_status'),
//     };
//     this.showModal = this.showModal.bind(this);
//     this.hideModal = this.hideModal.bind(this);
//   }

//   showModal = () => {
//     this.setState({ show: true });
//   };

//   hideModal = () => {
//     this.setState({ show: false });
//   };

//   componentDidMount() {
//       if(!this.state.pop_status){
//         setTimeout(() => this.setState({ show: true }), 3000);
//         sessionStorage.setItem('pop_status',1);
//       }
//   }

//   render() {
//     const customStyles = {
//       overlay: {
//         backgroundColor: 'rgba(0, 0, 0, 0)',
//         zIndex: '5', 
//       },
//       content: {
//         top: '50%',
//         left: '80%',
//         right: 'auto',
//         bottom: 'auto',
//         transform: 'translate(-80%, -50%)',
//         width: '355px',
//         height: '440px',
//         backgroundColor: '#8D589B',
//         textAlign:'right',
//         padding:'0px',
//         border: '4px solid rgb(204, 204, 204)',
//         borderRadius:'8px',
//         boxShadow: '0px 3px 20px',
//       },
//       '@media (max-width: 767px)': {
//         content:{
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: '90%',
//         }
//       },
//     };

//     return (
//       // <StyleRoot>
//         <Modal
//         isOpen={this.state.show}
//         onRequestClose={this.hideModal}
//         style={customStyles}
//         >
//           <ButtonClose onClick={this.hideModal}>x</ButtonClose>
//           <NudgeContent></NudgeContent>
//         </Modal>
//     // </StyleRoot>
//     );
//   }
// }

// // export default Radium(Nudge);
// export default Nudge;